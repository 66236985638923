import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
  state: {
   partnerListing: [],
  },
  getters: {
    getPartnerListing: (state) => state.partnerListing,
  
  },
  actions: {
    fetchPartners({ commit}) {
        return new Promise((resolve,reject) => {
            axios.get(`${BASE_URL}/partner/all/partner/listing`).then((response) => {
                commit("setPartnerListing",response.data.data)
                resolve(response.data.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    updatePageLive({}, pagePayload) {
        return new Promise((resolve,reject) => {
            axios.put(`${BASE_URL}/partner/update/page/live`,pagePayload).then((response) => {
                resolve("success")
            }).catch(error => {
                reject(error)
            })
        })
    },
    approvedPageData({}, pagePayload) {
      return new Promise((resolve,reject) => {
          axios.put(`${BASE_URL}/partner/approved/page/data`,pagePayload).then((response) => {
              resolve("success")
          }).catch(error => {
              reject(error)
          })
      })
    }
  },
  mutations: {
    setPartnerListing: (state, partnerData) => (state.partnerListing = partnerData),
    
  },
};
