import axios from "axios";
let BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL : "";
export default {
  state: {
   userDetails: [],
   roleDetails: [],
   userCategoryDetails: [],
   currentRoleDetails: []
  },
  getters: {
    getUserDetails: (state) => state.userDetails,
    getRoleDetails: (state) => state.roleDetails,
    getUserCategoryDetails: (state) => state.userCategoryDetails,
    getCurrentRoleDetails: (state) => state.currentRoleDetails
  },
  actions: {
    fetchUserDetails({ commit}) {
      axios.get(`${BASE_URL}/auth/admin/user`).then((response) => {
        commit("setUserDetails",response.data.user)
        //console.log(response.data.admin)
        commit("setAdminRole",response.data.admin)
      });
    },
    checkUserAdmin({commit}, role){
      return new Promise((resolve,reject) => {
        axios.get(`${BASE_URL}/users/admin/access/${role}`).then((response) => {
          commit("setCurrentAdminRole", response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchUserByCategory({ commit}, user_type) {
      axios.get(`${BASE_URL}/users/category/${user_type}`).then((response) => {
        commit("setUserCategoryDetails",response.data.data)
      });
    }
  },
  mutations: {
    setUserDetails: (state, userData) => (state.userDetails = userData),
    setAdminRole: (state, roleData) => (state.roleDetails = roleData),
    setCurrentAdminRole: (state, roleData) => (state.currentRoleDetails = roleData),
    setUserCategoryDetails: (state, categoryData) => (state.userCategoryDetails = categoryData)
  },
};
